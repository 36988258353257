.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.wrapper{
  background-color: linea;
  min-width:30vw;
  /* max-width: 50vw; */
  height: 380px;
  border-radius: 40px;
  overflow: hidden;
  margin: 10px;
}

.title-content {
  text-align: center;
  max-width: fit-content;
  word-wrap: break-word;
  margin-left: auto;
  margin-right: auto;
}

.productSpecifications {
  background-color: rgb(238,161,154);
  color: black;
  font-weight: 700;
}


.productinfo {
    background-color: rgb(200,76,75);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    min-height: 200px;
    /* margin-bottom: 50px; */
}

.productImage {
  animation: wiggle infinite 1s;
  -webkit-animation:  wiggle infinite 1s;
}

.wrapper:hover .productImage {
  animation: imageAnimation 1s forwards alternate ;
  transition: ease 2s all ;
  -webkit-animation: imageAnimation 1s forwards alternate ;
}

img {
  width: 60%;
}

p {
  font-size: 10px;
  opacity: 0.9;
} 
  
.featureIcon{
  width: 40px;
  height: 40px;
  background-image: var(--bg-shape-color);
  border-radius: 8px; 
    margin-right: 16px;
}
.productFeatures {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px; 
  grid-row-gap: 16px;
}

.grouptext {
  font-size: 8px;
}

.feature{ display:flex;}




/* animation */

@keyframes imageAnimation{
  0%  {
    /* transform: translate(150%, 66px);
    transform: translateY((0px)); */
    /* opacity: 0; */
  }

  50% {
    /* opacity: 0.7; */
  }
    
  70% {
   /* opacity:  0.9; */
  }
  100%{
    /* transform: translate(0px, 66px); */
    transform: translateY(-300px);
    /* transform: translateY(calc(-200px - 100px)); */
    /* opacity: 1; */
    /* width:40vw;
    height: 50vh; */
  }
}

@keyframes wiggle {
  0% { transform: rotate(0deg); }
 25% { transform: rotate(0deg); }
 50% { transform: rotate(1deg); }
 75% { transform: rotate(-1deg); }
100% { transform: rotate(0deg); }
}