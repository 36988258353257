.app {
  text-align: center;
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgb(33, 29, 121) 0%, rgb(96, 96, 180) 35%, rgb(59, 77, 111) 100%);
}

.header {
  font-size: calc(20px + 2vmin);
  font-style: oblique;
  padding: 40px;
  color: rgb(248, 248, 248); 
  font-family: 'Helvetica Neue', sans-serif; 
  font-weight: bold; 
  letter-spacing: -1px; 
  line-height: 1; 
}

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}


